import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getSchBTCSReport = createAsyncThunk('schBReportSlice/getSchBTCSReport', async (data) => {
    try {
        let response = await axios.post('/schedule-b-tcs-report/with-filters', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getScheduleBTCSReport = createAsyncThunk('schBReportSlice/getScheduleBTCSReport', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.post('/schedule-b-tcs-report' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getScheduleBTCSReportDownload = createAsyncThunk('schBReportSlice/getScheduleBTCSReportDownload', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/schedule-b-tcs-report/download' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob',

            loader: true
        });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${'Schedule_B_Tcs_Report'}.xlsx`;

        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

        return Promise.resolve({ message: 'Excel file downloaded successfully' });
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSchBAnnex1Report = createAsyncThunk('schBReportSlice/getSchBAnnex1Report', async (data) => {
    try {
        let response = await axios.post('/schedule-b-annex-1-report/with-filters', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const schBReportSlice = createSlice({
    name: 'schBReportSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSchBTCSReport.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getScheduleBTCSReport.fulfilled, (state, action) => {
            state.reportData = action?.payload;
            return;
        });
    }
});

export default schBReportSlice.reducer;
