import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';

export const getProject = createAsyncThunk('projectSlice/getProject', async (data, { dispatch }) => {
    try {
        let response = await axios.get('/project', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        await dispatch(removeSelectedProject());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getProjectOptions = createAsyncThunk('projectSlice/getProjectOptions', async (data, { dispatch }) => {
    try {
        let response = await axios.get('/project/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        await dispatch(removeSelectedProject());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getProjectTeams = createAsyncThunk('projectSlice/getProjectTeams', async (id) => {
    try {
        let response = await axios.get('/project-team/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createProject = createAsyncThunk('projectSlice/createProject', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/project', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project added successfully.');
        await dispatch(getProject());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const createProjectTeams = createAsyncThunk('projectSlice/createProjectTeams', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post('/project-team', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        // toast.success(response?.data?.message || 'Project Team added successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateProject = createAsyncThunk('projectSlice/updateProject', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/project/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project updated successfully.');
        await dispatch(getProject());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const updateProjectTeams = createAsyncThunk('projectSlice/updateProjectTeams', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/project-team/' + data.projectId, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteProject = createAsyncThunk('projectSlice/deleteProject', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project deleted successfully.');
        await dispatch(getProject());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteUser = createAsyncThunk('projectSlice/deleteUser', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project-team/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        // toast.success(response?.data?.message || 'Project deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('projectSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/project/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        await dispatch(getProject());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getProjectById = createAsyncThunk('projectSlice/getProjectById', async (id) => {
    try {
        let response = await axios.get('/project/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProjectStatusOptions = createAsyncThunk('projectSlice/getProjectStatusOptions', async () => {
    try {
        let response = await axios.get('/project-status/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getProjectDocumentById = createAsyncThunk('projectSlice/getProjectDocumentById', async (id) => {
    try {
        let response = await axios.get('/project-document/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProjectDocument = createAsyncThunk('projectSlice/createProjectDocument', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/project-document', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project Document added successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateProjectDocument = createAsyncThunk('projectSlice/updateProjectDocument', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/project-document/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project Document updated successfully.');
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);
        return rejectWithValue(err);
    }
});

export const projectSlice = createSlice({
    name: 'projectSlice',
    initialState: {
        data: {},
        teamsData: {},
        selectedProject: {},
        options: [],
        projectOptions: []
    },
    reducers: {
        removeSelectedProject: (state, action) => {
            state.selectedProject = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProject.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProjectOptions.fulfilled, (state, action) => {
            state.projectOptions = action?.payload;
            return;
        });
        builder.addCase(getProjectTeams.fulfilled, (state, action) => {
            state.teamsData = action?.payload;
            return;
        });
        builder.addCase(getProjectById.fulfilled, (state, action) => {
            state.selectedProject = action?.payload;
            return;
        });
        builder.addCase(getProjectDocumentById.fulfilled, (state, action) => {
            state.selectedProjectDocument = action?.payload;
            return;
        });
        builder.addCase(getProjectStatusOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export const { removeSelectedProject } = projectSlice.actions;
export default projectSlice.reducer;
