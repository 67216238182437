// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import category from '../views/Master/Category/store';
import subCategory from '../views/Master/SubCategory/store';
import brand from '../views/Master/BrandMaster/store';
import unitMaster from '../views/Master/UnitMaster/store';
import material from '../views/Master/Material/store';
import item from '../views/Master/Item/store';
import mixDesign from '../views/MixDesign/store';
import state from '../views/Location/State/store';
import district from '../views/Location/District/store';
import taluka from '../views/Location/Taluka/store';
import city from '../views/Location/City/store';
import materialCategory from '../views/Master/MaterialCategory/store';
import materialSubCategory from '../views/Master/MaterialSubCategory/store';
import supplier from '../views/Master/Supplier/store';
import stockIn from '../views/StockIn/store';
import department from '../views/Master/Department/store';
import designation from '../views/Master/Designation/store';
import projectOwner from '../views/ProjectOwner/store';
import project from '../views/Project/store';
import agency from '../views/Agency/store';
import roadType from '../views/Master/RoadType/store';
import appUser from '../views/AppUser/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import module from '../views/Setup/ModuleManage/store';
import config from '../views/Settings/Configuration/store/index';
import menuOrder from '../views/Settings/MenuOrder/store';
import profile from '../views/UserProfile/Profile/store';
import templateGallery from '../views/Settings/TemplateGallery/store';
import projectSettings from '../views/Settings/ProjectSetting/store';
import auditLog from '../views/AuditLogs/store/index';
import road from '../views/Project/component/road/store/index';
import schAnnex1 from '../views/Project/Profile/Tab/ScheduleAnnex1/component/AnnexOne/store/index';
import schAnnex2 from '../views/Project/Profile/Tab/ScheduleAnnex1/component/AnnexTwo/store/index';
// import structureType from '../views/ProjectSetup/StructureType/store/index';
import structureType from '../views/Master/StructureType/store/index';
import structureActivity from '../views/Master/StructureActivity/store/index';
import scheduleBTcs from '../views/Project/Profile/Tab/ScheduleAnnex2/component/TCS/store/index';
import schBAnnex1 from '../views/Project/Profile/Tab/ScheduleAnnex2/component/AnnexOne/store/index';
import tcs from '../views/Project/component/Tcs/store/index';
import workCategory from '../views/ProjectSet/store/index';
import SchGRoad from '../views/Project/Profile/Tab/ScheduleAnnex3/component/Road/store/index';
import SchBTcsReport from '../views/Project/Profile/ScheduleBReport/store/index';
import projectMixDesign from '../views/Project/component/MixDesign/store/index';
import itemMixture from '../views/Master/ItemsMixture/store/index';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    moduleSlice,
    category,
    subCategory,
    brand,
    unitMaster,
    material,
    item,
    mixDesign,
    state,
    district,
    taluka,
    city,
    materialCategory,
    materialSubCategory,
    supplier,
    stockIn,
    department,
    designation,
    projectOwner,
    agency,
    project,
    roadType,
    appUser,
    projectSettings,
    menuOrder,
    module,
    profile,
    templateGallery,
    config,
    auditLog,
    road,
    schAnnex1,
    schAnnex2,
    structureType,
    scheduleBTcs,
    schBAnnex1,
    tcs,
    structureActivity,
    workCategory,
    SchGRoad,
    SchBTcsReport,
    projectMixDesign,
    itemMixture
};

export default rootReducer;
