import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getAllItemMixture = createAsyncThunk('itemsMixtureSlice/getAllItemMixture', async (_, { dispatch }) => {
    try {
        let response = await axios.get('/item-mixture', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        await dispatch(removeSelectedItemsMixture());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getItemMixtureById = createAsyncThunk('itemsMixtureSlice/getItemMixtureById', async (id) => {
    try {
        let response = await axios.get('/item-mixture/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createItemMixture = createAsyncThunk('itemsMixtureSlice/createItemMixture', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/item-mixture', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item Mixture added successfully.');
        await dispatch(getAllItemMixture());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateItemMixture = createAsyncThunk('itemsMixtureSlice/updateItemMixture', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/item-mixture/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item Mixture updated successfully.');
        await dispatch(getAllItemMixture());
        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteItemMixture = createAsyncThunk('itemsMixtureSlice/deleteItemMixture', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/item-mixture/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Item Mixture deleted successfully.');
        await dispatch(getAllItemMixture());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return rejectWithValue(err);
    }
});
export const updateStatus = createAsyncThunk('itemsMixtureSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/item-mixture/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        await dispatch(getAllItemMixture());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const itemsMixtureSlice = createSlice({
    name: 'itemsMixtureSlice',
    initialState: {
        data: [],
        selectedItemsMixture: {}
    },
    reducers: {
        removeSelectedItemsMixture: (state, action) => {
            state.selectedItemsMixture = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllItemMixture.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getItemMixtureById.fulfilled, (state, action) => {
            state.selectedItemsMixture = action?.payload;
            return;
        });
    }
});

export const { removeSelectedItemsMixture } = itemsMixtureSlice.actions;
export default itemsMixtureSlice.reducer;
