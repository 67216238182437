import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getTcs = createAsyncThunk('tcsSlice/getTcs', async (id) => {
    try {
        let response = await axios.post('/schedule-b-tcs/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSchAAnnex1Options = createAsyncThunk('tcsSlice/getSchAAnnex1Options', async (data) => {
    try {
        let response = await axios.post('/sch-annex-1/chainage-wise-options', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createTcs = createAsyncThunk('tcsSlice/createTcs', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/schedule-b-tcs', data?.formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS added successfully.');
        dispatch(getTcs(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateTcs = createAsyncThunk('tcsSlice/updateTcs', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/schedule-b-tcs/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS updated successfully.');
        dispatch(getTcs(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});
export const deleteTcs = createAsyncThunk('tcsSlice/deleteTcs', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/schedule-b-tcs/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS deleted successfully.');
        dispatch(getTcs(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoadOptions = createAsyncThunk('tcsSlice/getRoadOptions', async (id) => {
    try {
        let response = await axios.get('/road/options/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getSchGRoadItemOptions = createAsyncThunk('tcsSlice/getSchGRoadItemOptions', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/sch-g-road-item-options' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createTCSItem = createAsyncThunk('tcsSlice/createTCSItem', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/tcs-item', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response.data.message || 'TCS Item Added Successfully');
        dispatch(getTCSItem(data?.tcsId));
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const updateTCSItem = createAsyncThunk('tcsSlice/updateTCSItem', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/tcs-item/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS updated successfully.');
        dispatch(getTCSItem(data?.data?.tcsId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const getTCSItem = createAsyncThunk('tcsSlice/createTCSItem', async (id) => {
    try {
        let response = await axios.get('/tcs-item/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const deleteTCSItem = createAsyncThunk('tcsSlice/deleteTCSItem', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/tcs-item/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'TCS deleted successfully.');
        dispatch(getTCSItem(data?.tcsId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const tcsSlice = createSlice({
    name: 'tcsSlice',
    initialState: {
        data: [],
        tcsItemData: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getTcs.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTCSItem.fulfilled, (state, action) => {
            state.tcsItemData = action?.payload;
            return;
        });
        builder.addCase(getRoadOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getSchGRoadItemOptions.fulfilled, (state, action) => {
            state.schGItemOptions = action?.payload;
            return;
        });
        builder.addCase(getSchAAnnex1Options.fulfilled, (state, action) => {
            state.annex1Options = action?.payload;
            return;
        });
    }
});

export default tcsSlice.reducer;
