import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getSchGRoad = createAsyncThunk('schGRoad/getSchGRoad', async (data) => {
    try {
        let response = await axios.post('/sch-g-road-all-item', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const creatSchGRoad = createAsyncThunk('schGRoad/creatSchGRoad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/sch-g-road-item', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SchG Road added successfully.');
        // dispatch(getSchGRoad(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});

export const updateSchGRoad = createAsyncThunk('schGRoad/updateSchGRoad', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/sch-g-road-item/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SchG Road updated successfully.');
        // dispatch(getSchGRoad(data?.projectId));

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        return rejectWithValue(err);
    }
});
export const deleteSchGRoad = createAsyncThunk('schGRoad/deleteSchGRoad', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/sch-g-road-item/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SchG Road deleted successfully.');
        // dispatch(getSchGRoad(data?.projectId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSchGRoadItem = createAsyncThunk('schGRoad/deleteSchGRoadItem', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/sch-g-road-item-delete/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'SchG Road deleted successfully.');
        // dispatch(getSchGRoad(data?.projectId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSchGRoadOptions = createAsyncThunk('schGRoad/getSchGRoadOptions', async (id) => {
    try {
        let response = await axios.get('/road/options/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const getSchGRoadReporting = createAsyncThunk('schGRoad/getSchGRoadReporting', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.post('/item-material-report' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const schGRoad = createSlice({
    name: 'schGRoad',
    initialState: {
        data: [],
        tcsItemData: [],
        options: [],
        schGRoadReporting: []
    },

    extraReducers: (builder) => {
        builder.addCase(getSchGRoad.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSchGRoadOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getSchGRoadReporting.fulfilled, (state, action) => {
            state.schGRoadReporting = action?.payload;
            return;
        });
    }
});

export default schGRoad.reducer;
