import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getProjectMixDesign = createAsyncThunk('projectMixDesignSlice/getProjectMixDesign', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/project-mix-design' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const createProjectMixDesign = createAsyncThunk(
    'projectMixDesignSlice/createProjectMixDesign',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.post('/project-mix-design', data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Project Mix Design added successfully.');
            // await dispatch(getProjectMixDesign({ projectId: data?.projectId, usesType: data?.usesType }));
            await dispatch(getMixtureSubItems({ projectId: data?.projectId, usesType: data?.usesType }));
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const deleteProjectMixDesign = createAsyncThunk('projectMixDesignSlice/deleteProjectMixDesign', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/project-mix-design/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Project Mix Design deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMixtureSubItems = createAsyncThunk('projectMixDesignSlice/getMixtureSubItems', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/item-mixture-item' + `?${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const updateProjectMixDesign = createAsyncThunk(
    'projectMixDesignSlice/updateProjectMixDesign',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await axios.put('/project-mix-design/' + data.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: true
            });
            toast.success(response?.data?.message || 'Project Mix Design updated successfully.');
            // await dispatch(getProjectMixDesign({ projectId: data?.data?.projectId, usesType: data?.data?.usesType }));
            await dispatch(getMixtureSubItems({ projectId: data?.data?.projectId, usesType: data?.data?.usesType }));
            return Promise.resolve(response.data);
        } catch (err) {
            if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            }
            console.log('err', err);

            return rejectWithValue(err);
        }
    }
);

export const projectMixDesignSlice = createSlice({
    name: 'projectMixDesignSlice',
    initialState: {
        data: [],
        mixtureSubItems: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProjectMixDesign.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getMixtureSubItems.fulfilled, (state, action) => {
            state.mixtureSubItems = action?.payload;
            return;
        });
    }
});

export default projectMixDesignSlice.reducer;
