import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getWorkCategory = createAsyncThunk('workCategorySlice/getWorkCategory', async (data) => {
    try {
        let response = await axios.get('/work-category', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: data?.loader
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createWorkCategory = createAsyncThunk('workCategorySlice/createWorkCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/work-category', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type added successfully.');
        await dispatch(getWorkCategory());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const updateWorkCategory = createAsyncThunk('workCategorySlice/updateWorkCategory', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/work-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type updated successfully.');
        dispatch(getWorkCategory());

        return Promise.resolve(response.data);
    } catch (err) {
        if (!err.response?.data.fields || err.response?.data?.fields?.length == 0) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
        console.log('err', err);

        return rejectWithValue(err);
    }
});

export const deleteWorkCategory = createAsyncThunk('workCategorySlice/deleteWorkCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/work-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Road Type deleted successfully.');
        await dispatch(getWorkCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('subCategorySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put(
            '/work-category/status/' + id,
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                loader: false
            }
        );
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getWorkCategory());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getWorkCategoryOptions = createAsyncThunk('projectSlice/getWorkCategoryOptions', async () => {
    try {
        let response = await axios.get('/work-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const workCategorySlice = createSlice({
    name: 'workCategorySlice',
    initialState: {
        data: [],
        options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getWorkCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getWorkCategoryOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
    }
});

export default workCategorySlice.reducer;
